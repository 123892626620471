window.addEventListener('turbo:load', function () {
  var togglers = Array.from(document.getElementsByClassName('toggler'))

  togglers.forEach(function (element) {
    element.addEventListener('click', function (clickEvent) {
      var elementToChange = document.getElementById(
        element.getAttribute('data-toggle-element-id')
      )
      elementToChange.classList.toggle(
        element.getAttribute('data-toggle-class')
      )
    })
  })
})
