window.addEventListener('turbo:load', function () {
  document.querySelectorAll('.app-toggler a').forEach(function (element) {
    element.addEventListener('click', function (clickEvent) {
      var classes = document.querySelector('#navigation .apps').classList
      classes.toggle('menu-open')
      clickEvent.preventDefault()
      clickEvent.stopPropagation()
    })
  })
})
