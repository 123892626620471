'use strict'

var headers = []

window.onscroll = function () {
  stickify()
}

window.addEventListener('turbo:load', function (event) {
  Array.from(document.getElementsByClassName('stickable')).forEach(function (
    element
  ) {
    var spacer = document.createElement('div')
    element.parentElement.insertBefore(spacer, element)
    headers.push({
      element: element,
      initialTop: element.offsetTop,
      spacer: spacer,
    })
  })
})

function stickify() {
  headers.forEach(function (header) {
    if (window.pageYOffset > header.initialTop) {
      var height = header.element.getBoundingClientRect().height
      header.spacer.style.height = height + 'px'
      header.element.classList.add('stuck')
    } else {
      header.spacer.style.height = 0
      header.element.classList.remove('stuck')
    }
  })
}
