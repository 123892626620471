var bootstrap, d, hide, show

d = document

hide = function (el) {
  return (el.style.display = 'none')
}

show = function (el) {
  return (el.style.display = 'block')
}

bootstrap = function () {
  var btn, cloak, cloaks, i, len
  cloaks = d.querySelectorAll('.cloaked')
  for (i = 0, len = cloaks.length; i < len; i++) {
    cloak = cloaks[i]
    hide(cloak)
  }
  btn = d.querySelector('#feature-tour-button')
  if (btn) {
    return btn.addEventListener('click', function (e) {
      var iframe
      hide(d.querySelector('#feature-tour-button-section'))
      show(d.querySelector('#feature-tour-section'))
      iframe = d.querySelector('#feature-tour-section iframe')
      iframe.setAttribute('src', iframe.getAttribute('x-src'))
      d.querySelector('a[id=featureTour]').scrollIntoView()
      e.preventDefault()
      return e.stopPropagation()
    })
  }
}

d.addEventListener('turbo:load', function (e) {
  return bootstrap()
})
