import * as fitvids from './deps/fitvids.min.js'

window.addEventListener('turbo:load', function () {
  fitvids()
})

function startVideos() {
  const video = Array.from(document.querySelectorAll('video:not(.loaded)'))[0]
  if (!video) {
    return
  }
  const sources = JSON.parse(atob(video.getAttribute('data-sources')))
  sources.forEach(function (source) {
    source.canPlayType = video.canPlayType(source.type) !== ''
  })
  const playableSource = sources.filter(function (s) {
    return s.canPlayType
  })[0]
  if (playableSource) {
    video.classList.add('loaded')
    video.oncanplaythrough = function () {
      startVideos()
    }
    video.src = playableSource.src
  }
}

const prefersReduceMotion = window.matchMedia(
  '(prefers-reduced-motion: reduce)'
).matches

if (prefersReduceMotion) {
  console.log('User prefers reduced motion. Videos will not play.')
} else {
  window.addEventListener('turbo:load', startVideos)
}
