window.addEventListener('turbo:load', function () {
  console.log('turbo:load')
  var allChoices = Array.from(
    document.getElementsByClassName('zine-tab-choice')
  )

  function updateTabActiveState() {
    allChoices.forEach(function (element) {
      var tabContent = document.getElementById(
        element.getAttribute('data-tab-id')
      )
      var isCurrent = tabContent.classList.contains('active-tab')
      if (isCurrent) {
        element.classList.add('current')
      } else {
        element.classList.remove('current')
      }
    })
  }

  function selectTab(id, group) {
    Array.from(document.getElementsByClassName(group)).forEach(function (
      content
    ) {
      content.classList.remove('active-tab')
      content.classList.add('hidden-tab')
    })
    var tabContent = document.getElementById(id)
    tabContent.classList.add('active-tab')
    tabContent.classList.remove('hidden-tab')
    updateTabActiveState()
  }

  allChoices.forEach(function (element) {
    element.addEventListener('click', function (clickEvent) {
      selectTab(
        element.getAttribute('data-tab-id'),
        element.getAttribute('data-tab-group-class')
      )
    })
  })

  updateTabActiveState()
})
