window.addEventListener('turbo:load', function () {
  var allChoices = Array.from(
    document.getElementsByClassName('zine-selector-choice')
  )

  function updateCurrentChoices() {
    allChoices.forEach(function (element) {
      var isCurrent =
        document
          .getElementById(element.getAttribute('data-selected-image-id'))
          .getAttribute('src') == element.getAttribute('data-src')
      if (isCurrent) {
        element.classList.add('current')
      } else {
        element.classList.remove('current')
      }
    })
  }

  allChoices.forEach(function (element) {
    element.addEventListener('click', function (clickEvent) {
      var elementToChange = document.getElementById(
        element.getAttribute('data-selected-image-id')
      )
      elementToChange.setAttribute('src', element.getAttribute('data-src'))
      updateCurrentChoices()
    })
  })

  updateCurrentChoices()
})
